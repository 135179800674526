<h1 mat-dialog-title>Sherlock Holmes Consulente Investigativo</h1>
<div mat-dialog-content>
    <p>Sherlock Holmes Consulente Investigativo è un gioco collaborativo in cui,<br>
        come membri della banda degli Irregolari di Baker Street, cercherete di <br>
        risolvere dei casi misteriosi girando per le strade in cerca di indizi.</p>
    <p>Il gioco è distribuito da  <a mat-stroked-button href="https://www.asmodee.it/giochi_sherlock.php"
        target="_blank">Asmodee</a></p>
    <p>Contenuti:</p>
    <ul>
    <li><a mat-stroked-button href="https://www.asmodee.it/giochi/SHCI/SHCI_C1_regolamento.pdf" target="_blank">Il
        Regolamento</a></li>
    </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">OK</button>
</div>
