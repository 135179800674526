<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-button (click)="lbar.toggle()">
      <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon">menu</mat-icon>
      <span>Times</span>
    </button>
    <span class="example-spacer"></span>
    <span class="title">Sherlock Holmes - Consulente Investigativo</span>
    <button mat-icon-button (click)="gameInfo()"><mat-icon>info</mat-icon></button>


    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      Avventure
    </button>
    <span class="example-spacer"></span>
    <button mat-button (click)="rbar.toggle()">
      <span>Annuario</span>
      <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon">menu</mat-icon>
    </button>
  </mat-toolbar-row>

</mat-toolbar>

<mat-drawer-container class="example-container">
  <mat-drawer class="sidebar-wide" #lbar mode="over" opened>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let timed of times" [expanded]="timed.open">
        <mat-expansion-panel-header>
          {{timed.title}}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <img *ngFor="let time of timed.pages" src="{{time.url}}" width="215" (click)="show('The Times', time.url, 900)">
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-drawer>

  <mat-drawer class="sidebar" #rbar position="end" mode="over" opened>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let annu of annuario" [expanded]="annu.open">
        <mat-expansion-panel-header>
          {{annu.title}}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <img *ngFor="let ann of annu.pages" src="{{ann.url}}" width="240" (click)="show('Annuario', ann.url, 800)">
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-drawer>
  <mat-drawer-content>
    <div id="map">MAP</div>
  </mat-drawer-content>
</mat-drawer-container>

<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="chooseAdventure()">Scegli Avventura</button>
  <button *ngIf="adv.adventureSelected" mat-menu-item (click)="solveAdventure()">Rileggi introduzione</button>
  <button *ngIf="adv.adventureSelected" mat-menu-item (click)="solveAdventure()">Risolvi il caso</button>
  <a href="https://advenutes.sherlock.justplaybo.it" target="_blank" mat-menu-item>Crea Avventura...</a>
</mat-menu>

